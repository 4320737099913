import styled from 'styled-components'

export const LinedHeader = styled.h2`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Kaushan Script', cursive;
  font-size: 40px;
  &::after, &::before {
    content: "";
    border-top: 1.5px solid;
    margin: 0 20px 0 20px;
    flex: 1 0 20px;
    color: #F1BA43;
  }
`
