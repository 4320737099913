import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import styled from "styled-components";
import Header from "../components/header";
import Footer from '../components/footer';
import { LinedHeader } from '../components/styledComponents';

const P = styled.p`
  font-size: 18px;
`

const useStyles = makeStyles((theme) => ({
  faq: {
    padding: '0 60px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px'
    }
  }
}));

const Faq = () => {
  const classes = useStyles();

  return (
    <div>
      <Header title="FAQs" />
      <Grid container className={classes.faq}>
        <Grid container item xs={12} justify="center" >
          <LinedHeader>Placing an order</LinedHeader>
          <Grid item>
            <P>Orders can be placed by contacting us on:</P>
              <ul>
                <li><a href="https://www.facebook.com/sprinklesomething" target="_blank" rel="noreferrer">Facebook</a></li>
                <li><a href="https://www.instagram.com/sprinklesomething" target="_blank" rel="noreferrer">Instagram</a></li>
              </ul>
            <P>Let us know the occasion type, date of pick up, and any additional details to fulfill your needs.</P>
          </Grid>
        </Grid>

        <Grid item xs={12} container justify="center">
          <LinedHeader>Payment</LinedHeader>
          <P>Payment can be made through Venmo or Zelle.</P>
          <P>Custom orders require a 50% non-refundable down payment when they are placed. The other 50% will be charged at pickup time.</P>
        </Grid>

        <Grid item xs={12} container justify="center">
          <LinedHeader>Food Allergies</LinedHeader>
          <P>Please let us know of any food allergies. Some items contain wheat/gluten, nuts, and/or milk.</P>
        </Grid>

        <Grid item xs={12} container justify="center">
          <LinedHeader>Cancellations</LinedHeader>
          <P>For CUSTOM CAKES - Custom Cake orders must be placed at least 3 weeks in advance. A lot of planning goes into making your cake just the way you want it. Therefore, we charge a 50% NON-REFUNDABLE down payment when a custom cake order is placed.</P>
          <P>For all other treats - Orders must be placed atleast 1 week in advance. Cancellations must be requested 4 days before pick up date.</P>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Faq;
